import React, { useContext } from 'react';
import useTranslations from "../../utils/useTranslations"
import Button from "../../components/button"
import heroImg from "../../images/token/bittorrent-file-system/btfs-logo.svg"
import soterMarkImg from "../../images/token/bittorrent-file-system/soter-mark.png"
import ecosystemImg from "../../images/token/bittorrent-file-system/ecosystem.svg"
import node1Img from "../../images/token/bittorrent-file-system/node1.png"
import node2Img from "../../images/token/bittorrent-file-system/node2.png"
import node3Img from "../../images/token/bittorrent-file-system/node3.png"
import roadmapBgImg from "../../images/token/bittorrent-file-system/roadmap-bg.png"
import LocalizedLink from "../../components/localizedLink"
import backgrounds from "../../components/backgrounds"
import ReleaseNotes from "../../components/releaseNotes"
import { graphql } from "gatsby"
import { CompatibilityContext } from "../../components/compatibilityContext";
import Roadmap from "../../components/roadmap"
import { FaTwitter, FaTelegram, FaDiscord, FaGithub } from 'react-icons/fa';
import { SiWechat } from "react-icons/si";

const TokenBittorrentFileSystem = ({ pageContext: {text}, data }) => {

const t = useTranslations(text)
const heroImgAlt = "BTFS Logo"

const compatibilityHandler = useContext(CompatibilityContext)
function handleClick(e, action, product, link){
    compatibilityHandler(e, action, product, ["Win"], link)
}

//show a different Windows download link for window 7 and below users
const isBrowser = typeof window !== "undefined";
const ua = isBrowser ? window.navigator.userAgent : "";
const isWin7AndAbove = isBrowser && ua.includes("Windows NT") ? parseFloat(ua.split("Windows NT")[1].split(';')[0]) > 6.1 : undefined;
const btfsLink = isWin7AndAbove || isBrowser && !ua.includes("Windows") ? "https://download-new.utorrent.com/endpoint/bittorrent/os/windows/track/stable/" : "https://download-new.utorrent.com/endpoint/bittorrent/track/beta/os/win7";

return (
  	<>
        {/* Start - hero */}
        <div className="top-section-container pb-5 btfs-hero">
          <div className="btfs-secondary-nav d-flex justify-content-between text-white align-items-center">
            <div className="text-nowrap">
                <a href="https://uptime.btfs.io/" id="token-btfs-hero-uptime-monitor" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none">
                    {t(`Uptime monitor`)}
                </a>
                <LocalizedLink to="/token/bittorrent-file-system/faq" id="token-btfs-hero-faq" className="text-decoration-none ml-3">
                    <span className="text-white text-decoration-none">
                    {t(`FAQ`)}
                    </span>
                </LocalizedLink>
            </div>
            <div className="text-nowrap ml-5">
                <a id="token-btfs-hero-twitter" href="https://twitter.com/BitTorrent" className="twitter socialicon text-white" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                <a id="token-btfs-hero-telegram" href="https://t.me/BTTBitTorrent" className="telegram socialicon text-white ml-3" target="_blank" rel="noopener noreferrer"><FaTelegram /></a>
                <a id="token-btfs-hero-discord" href="https://discord.gg/pMSDKhG4m7" className="discord socialicon text-white ml-3" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
                <a id="token-btfs-github" href="https://github.com/TRON-US/go-btfs" className="discord socialicon text-white ml-3 pr-3 pr-lg-0" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
            </div>
          </div>

          <div className="container py-5 text-center position-relative">

          	<img src={heroImg} alt={heroImgAlt} className="img-fluid pb-5 btfs-logo"></img>
          	<h1 className="text-size-32 text-white btfs-hero-title-1 font-weight-bold">{t(`The First Scalable Decentralized Storage System`)}</h1>
          	<p className="text-size-20 text-white btfs-hero-title-2">{t(`A Foundational Platform for Decentralized Applications`)}</p>
            <div className="d-md-block mt-2">
                <Button 
                    href={btfsLink}
                    onClick={(e) => {handleClick(e, "downloading", "btfs", btfsLink) }} 
                    id="token-btfs-hero-download" 
                    className="btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled btfs-button"
                >
                    {t(`Free Download`)}
                </Button>
            </div>

          </div>
        </div> 
        {/* End - Hero */}

        {/* Start - why section */}
        <div className="container pt-5 text-center position-relative">
            <div className="container btfs-node-container d-flex justify-content-center">
            	<div className="">
            		<img src={node1Img} className="img-fluid mb-5 btfs-img-node" alt="!00M BitTorrent User Nodes"></img>
            	</div>
            	<div className="mx-3">
            		<img src={node2Img} className="img-fluid mb-5 btfs-img-node" alt="1000+ TRON Full Nodes"></img>
            	</div>
            	<div className="">
            		<img src={node3Img} className="img-fluid mb-5 btfs-img-node" alt="27 SR Nodes"></img>
            	</div>
            </div>
        	<div className="offset-md-2 col-md-8 pt-5 mt-5 px-md-5 btfs-section btfs-section-light">
	        	<p className="text-size-40 token-section-header-title mt-md-5">{t(`Why BTFS?`)}</p>
	        	<p className="text-center">{t(`Traditional file storage systems have many inherent disadvantages, such as centralized trust, overarching control by a single authority or organization, ability of governments to impose censorship, high cost, lock-in effect, and low fault tolerance.`)}</p>
        	</div>
        </div>
        {/* End - why section */}

        {/* Start - btfs ecosystem */}
        <div className="container-fluid">
	        <div className="container px-0 py-3 my-5">
	        	<div className="row pb-md-5">
	        		<div className="col-md-6 text-center text-md-left mb-5 mb-md-0 mt-md-5">
	        			<img src={ecosystemImg} alt="The five components of the BTFS ecosystem" className="img-fluid btfs-img-ecosystem"></img>
	        		</div>
	        		<div className="col-md-6 d-flex flex-column justify-content-center">
	        			<p className="text-size-30 font-weight-bold mb-4">{t(`The BTFS Ecosystem Will Have Five Components`)}</p>
                        <ul className="btfs-ecosystem-list text-left">
                			<li>{t(`The BTFS network is the next generation of decentralized storage systems.`)}</li>
                			<li>{t(`TRON network is one of the most important blockchain & DApp platforms in the world.`)}</li>
                			<li>{t(`The BitTorrent network brings with it 100 million decentralized users via the wide array of client products.`)}</li>
                			<li>{t(`TronGrid services one of the most important gateways connecting the components of the ecosystem.`)}</li>
                        </ul>

	        		</div>
	        	</div>
                <div className="row my-md-5 btfs-section btfs-section-light">
                    <div className="col-md-6 order-2 order-md-1 d-flex flex-column justify-content-center text-center text-md-left">
                        <p className="text-size-30 text-dark font-weight-bold">{t(`BTFS Gateway`)}</p>
                        <p>{t('BTFS gateway is a gateway based on the Tron network and the BTFS decentralized network. Anyone can access files already stored on the BTFS network via BTFS gateway. The operation is very simple and can be found in one click on Google Chrome.')}</p>
                        <div className="text-center text-md-left mt-4 mt-md-0">
                            <a href="https://docs.btfs.io/docs/btfs-gateway-user-guide" id="token-btfs-gateway-docs" target="_blank" rel="noreferrer noopenner" className="text-spacing-1 mt-0 mt-md-3 mb-4 d-inline font-weight-bold btfs-blue text-decoration-none">{t(`Learn More`)} ></a>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2 text-center text-md-right my-5 my-md-5">
                        <img src={soterMarkImg} className="img-fluid" alt="Soter"></img>
                    </div>
                </div>
	        </div>
        </div>
        {/* End - btfs ecosystem */}

        {/* Start - roadmap */}
        <div className="container-fluid btfs-section btfs-roadmap-container px-0 mt-5">
            <div className="btfs-roadmap-container-mask py-5 px-3">
    	        <div className="container px-0 py-5 text-center text-white">
    	        	<p className="text-size-40 token-section-header-title-white">{t(`BTFS Roadmap`)}</p>
                    <Roadmap data = {data.allWordpressAcfRoadmaps.nodes[0].acf.Roadmaps}/>
                </div>
            </div>
        </div>
        {/* End - roadmap */}
	</>
  )
}

export default TokenBittorrentFileSystem

export const pageQuery = graphql`
query queryBtfsReleaseNotes {
    allWordpressWpCustomRelease(filter: {release_cat: {eq: 1802}}) {
        edges {
          node {
            content
            date
            title
            fields {
              version
              elements {
                detailed_release_notes
                fixes
                improvements
              }
            }
          }
        }
    }
    allWordpressAcfRoadmaps(filter : {wordpress_id: {eq: 8868}}) {
        nodes {
          wordpress_id
          acf {
            Roadmaps {
              quarter
              year
              spotlight_quarter
              milestones {
                milestone_line_item
                milestone_completed
              }
            }
          }
        }
    }
}

`
